body {
  font-family: 'poppins', sans-serif;
  padding: 0;
  color: #333;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  background-color: #e0f7fa;
  overflow-x: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.home-header {
  margin-bottom: 40px;
}

.home-header .logo {
  width: 150px;
  height: auto;
}

.home-header h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  color: #004d40;
}

.home-header p {
  font-size: 1.2em;
  color: #004d40;
}

.login-back {
  width: 100%;
  height: 60px;
  background-color: #e0f7fa;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
  margin: 10px;
}

.btn-primary {
  background-color: #00796b;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #004d40;
  transform: scale(1.05);
}

.btn-secondary {
  background-color: #004d40;
  color: #fff;
  border: none;
}

.btn-secondary:hover {
  background-color: #00796b;
  transform: scale(1.05);
}

.sticky-background {
  position: fixed;
  top: 0;
  right: 0px;
  z-index: 999;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #e0f7fa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.home-main {
  width: 100%;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
}

.home-hero {
  background-color: #004d40;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.home-hero h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.home-hero p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.home-screenshot, .home-features, .home-about {
  margin-bottom: 40px;
}

.home-screenshot .screenshot {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}

.home-features ul {
  list-style-type: none;
  padding: 0;
}

.home-features li {
  background-color: #e0f2f1;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.home-features h3 {
  margin-bottom: 10px;
}

.home-footer {
  margin-top: 40px;
  color: #004d40;
}

.home-footer a {
  color: #004d40;
  text-decoration: none;
  margin: 0 10px;
}

.home-footer a:hover {
  text-decoration: underline;
}

.home-gif {
  max-width: 80%;
  border-radius: 30px;
  border: #004d40 5px solid;
  max-height: 600px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .home-header .logo {
      width: 150px;
  }

  .home-header p {
      font-size: 1.2em;
  }

  .btn {
      font-size: 1em;
  }

  .home-hero h2 {
      font-size: 2em;
  }

  .home-hero p {
      font-size: 1.2em;
  }

  .home-main {
      padding: 60px;
      max-width: 800px;
  }

  .home-features ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
  }

  .home-features li {
      flex: 1 1 calc(45% - 20px);
      margin: 20px;
  }
}
