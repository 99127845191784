.modal.join h2.join_g{
text-align: center;
}

.modal.join ul.group_members_list{
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 1em;
}

.modal.join ul.group_members_list li{
background-color: #cecccc;
color: #072E48;;
padding: 8px;
border-radius: 10px;
margin-top: 1em;
}

.modal.join .join_btn{
    all: unset;
    display: inline-block;
    padding: 10px 20px;
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.modal.join p{
text-align: center;
}