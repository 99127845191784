.Times{
display: flex;
flex-direction: column;
/* margin-bottom: 1em;
margin-top: 1em; */
align-items: center;
}

.Time_point{
background-color: #004d40;
padding: 5px;
display: flex;
justify-content: center;
align-items: center;
/* margin-bottom: 1em; */
/* width: 40%; */
width: 100%;
margin-left: auto; 
margin-right: auto; 
/* border-radius: 15px; */
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); 
}
.Time_point span{
    color: white;
}

span.line {
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, #BDBDBD 50%, transparent 50%); /* Adjust the percentage to control dash width */
    background-size: 20px 1px; 
}
