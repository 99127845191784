.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: unset;
    margin: 15% auto;
    border:none;
    width: 80%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.close-btn:hover,
.close-btn:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.name_and_emoji{
display: flex;
justify-content: center;
align-items: center;
gap: 1em;
}

.emoji_picker_btn{
    all: unset;
    display: block;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: aliceblue;
    height: 30px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2); 
}
.add_expense_dropdown.group_drop{
  width: 100%;  
}
.currency_explain{
color: #aaaaaa;
font-style: italic;
}

.create_group_btn{
    all:unset;
    display: inline-block;
    padding: 10px 20px;
    /* color: #fff;
    background-color: #007bff; */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;


    border: 1px solid #00796b99;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #e0f2f1;
    color: #004d40;
}
