.Total{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
    align-items: center;
    flex-direction: column;
    gap: 0.2rem;
}
.Total .total_and_btn{
display: flex;
gap: 1em;
justify-content: center;
align-items: center;
}
.Total .total_and_btn .total_details{
all: unset;
height: fit-content;
padding: 8px;
font-size: 0.8rem;
box-shadow: 0 0 5px rgba(0,0,0,0.2);
border-radius: 10px;
display: flex;
justify-content: center;
align-items: center;
}
.Total_amount{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.Total_amount h2{
    font-size: 2.5rem;
    margin: 0;
}
.Total_amount span{
    font-size: 2.5rem;
    margin: 0;
}
.Total h3{
    margin: 0;
    font-weight: 300;
    margin-top: 0.5em;
}
 .balance-positive {
    color: white;
    background-color: rgb(12, 192, 96);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.Total .balance-negative {
    color: white;
    background-color: rgb(234, 80, 80);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.Total h3 span{
margin-left: 1em;
}


.middle_mesg{
width: 70%;
display: flex;
justify-self: center;
justify-content: center;
align-items: center;
}
.no_g{
text-align: center;
color: #aaaa;
font-weight: 400;
}

.icon-rotate{
    transform: rotate(180deg);
}

.settle-bill-btn {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;

    border: 1px solid #00796b99;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #e0f2f1;
    color: #004d40;
}

.Balances{
background-color:#e0f7fa;
padding: 10px;
border-radius: 10px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
margin-top: 1em;
}

.Balances_row{
display: flex;
justify-content: space-between;
margin-top: 1em;
}