.expenses-list {
    width: calc(100% + 50px); /* 100% width plus padding of .Main */
    margin: 0 -20px; /* Negative margin equal to .Main padding */
    font-family: 'Poppins', sans-serif;
}

.expense_cont {
    display: flex;
    flex-direction: column;
}

.expense {
    list-style: none;
    padding: 5px;
    margin: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    margin-top: 1em;
    align-items: flex-start;
    gap: 0.5rem;

    position: relative;
    padding-left: 15px;
    margin-bottom: 0;
}

.expense_cont:nth-child(odd) {
    background-color: #e0f2f1; /* Light color */
}

.expense_cont:nth-child(even) {
    background-color: #00796b99; /* Dark color */
}

.user_img {
    background-color: whitesmoke;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: solid 3px #F8F8FF;
}

.expense_details {
    padding: 0;
    padding-top: 10px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

.expense_main {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%; 
}

.Item_name {
    font-weight: 600;
    padding: 5px;
}

.item_details {
    display: flex;
    padding: 5px;
    gap: 3px;
    align-items: baseline;
}

.item_final_price {
    font-weight: 600;
    padding: 5px;
}

.expense_buttons {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: inherit;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 0.5em;
    width: 80%;
}

.expense.show-buttons {}

.expense_buttons button {
    flex: 1; 
    border: none; 
    color: darkslategrey;
    padding: 10px 20px; 
    cursor: pointer; 
    outline: none;
    font-size: 0.8em;
    background: whitesmoke;
    border-radius: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.expense .added_by {
    font-size: small;
    color: #004d40;
}

.expense_img {
    display: block; 
    width: 85%;
    height: 15em; 
    object-fit: cover; 
    border-radius: 10%; 
    margin: auto; 
    margin-bottom: 1em;
    margin-right: 0;
}

.image_zoom_modal {
    position: fixed; /* Stay in place */
    z-index: 200; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
    display: flex;
    justify-content: center;
    align-items: center;
}

.expense span.item_currency {
    font-size: smaller;
}

.expense_shares {
    margin-top: 1em;
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
}

.expense_shares h4{
margin-bottom: 0;
}

.share_detail {
    display: flex;
    justify-content: space-between;
    padding: 0.2em 0;
}
