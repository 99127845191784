body {
    font-family: 'Poppins', sans-serif;
    font-display: swap;
    margin: 8px;
    margin: auto;
  }

  /* Base styles for mobile */
.container {
    width: 100%;
  }
  
  /* Override styles for larger screens - desktop */
  @media (min-width: 768px) {
    .container {
      width: 75%;
    }
  }

  span img{
  height: 2em;
  }

  input, button, select{
  font-family: 'Poppins', sans-serif;
  }