ul.invitations_list, ul.invitations_list li{
margin: 0;
}

.invitation_status{
font-weight: 700;
}
.invitation_group_name{
    padding: 10px;
    background-color:  #bdbdbdbf;;
    border-radius: 10px;
    margin-right: 1em;
}

.invitation_btns{
margin-top: 1em;
}
.approve_btn {
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1em;
}

.decline_btn {
    background-color: #f44336; /* Red background */
    color: white; /* White text */
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1em;
    margin-left: 1em;
}
