.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f2f5;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.login-box {
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white; 
    text-align: center; 
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.login-title {
    margin-bottom:1em; 
    color: #333; 
    font-size: 2em; 
}

.google-login-btn {
    font-size: 1em;
    padding: 10px 24px;
    border: none;
    border-radius:10px;
    cursor: pointer;
    background-color: #4285f4; /* Google's brand color */
    color: white; /* White text color */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

.google-login-btn:hover {
    background-color: #357ae8; /* Slightly darker shade on hover */
}

.google-login-btn span img{
    background: white;
    padding: 5px;
    border-radius: 10px;
}

.login_span_home{
color: lightslategray;
font-size: 0.8rem;
}
.login-box a{
all: unset;
}