.Date_row{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;
}

.time_frame, .add_partner_btn{
    display: block;
    padding: 8px 12px; 
    font-size: 0.9em; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    background-color: #fff; 
    color: #333; 
    width:33%; 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    height: fit-content;
    text-align: center;
}

.invitation_link{
width: 100%;
word-wrap: break-word;
}

.add_person{
height: 38px;
width: 38px;
margin-bottom: 2px;
color: #004d40;
}

.add_firend_to{
    color: #004d40; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    font-weight: 400;
    height: 60px;
    width: 60px;
    font-family: 'poppins', sans-serif;
}

.Date_row .custom_date{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

.Date_row .custom_date input[type="date"]{

    all: unset;
    margin-bottom: 5px;
    width: 80%;
    padding: 8px 12px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #333;
    height: -moz-fit-content;
}