.hamburger-menu {
    position: relative;
    display: flex;
    justify-content: flex-end;
    z-index: 1001;
    margin-bottom: 1em;
    width: 100%;
    font-family: 'Poppins', sans-serif;
}

.hamburger-menu .greeting{
    font-size: 1.5em; /* Adjust the size as needed */
    color: #072E48; /* Dark grey color for the text */
}

.hamburger-button {
    background: none;
    border: none;
    font-size: 10px;
    cursor: pointer;
}

.hamburger-line {
    display: block; /* Makes each span a block element to occupy the full width */
    width: 30px; /* Width of each line */
    height: 3px; /* Height of each line */
    background-color: #004d40; /* Color of the hamburger lines */
    margin: 5px 0; /* Adds space between the lines */
    transition: all 0.3s ease-in-out; /* Smooth transition for any animation */
    border-radius: 10px;
  }

.hamburger-button.open .hamburger-line.top {
    transform: translateY(8px) rotate(45deg);
}

.hamburger-button.open .hamburger-line.middle {
    opacity: 0;
}

.hamburger-button.open .hamburger-line.bottom {
    transform: translateY(-8px) rotate(-45deg);
}

/* Ensures the hamburger lines transition smoothly */
.hamburger-button .hamburger-line {
    transition: transform 0.3s ease, opacity 0.2s ease;
}

.menu-content {
    margin-top: 1em;
    position: absolute;
    top: 100%;
    right: 0; 
    width: 150%;
    border-radius: 10px;
}

.menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border: #004d40 2px solid;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.menu-content li {
    padding: 12px;
    padding-top: 20px;
    padding-bottom: 15px;
    margin-left: 20px;
    border-bottom: 1px solid #eee;
    font-size: 1em;
    color: #333;
}

.menu-content li a {
    text-decoration: none;
    color: #333;
    display: block;
}

.menu-content li:last-child {
    border-bottom: none;
}

.hamburger-menu li button.menu_btn{
    text-decoration: none; 
    border: none; 
    padding: 0; 
    cursor: pointer; 
    background: none;
    font-size: medium;
    color: #333;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 0.5em;
}

.hamburger-menu li button.menu_btn.logout{
color: red;
}

.user_greet{
    display: flex;
    align-items: center;
    gap: 1em;
}
.user-avatar {
    width: 50px;
    height: 50px; 
    border-radius: 50%; 
    object-fit: cover; 
}

ul.friend-list{
border: none;
}
.img_name{
display: flex;
flex-direction: row;
gap: 1em;
align-items: center;
}
.firend-item{
display: flex;
flex-direction: column;
gap: 1em;
}

.group_list_item{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    color: #004d40;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 0.2em 0.8em;
}