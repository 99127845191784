.settings-container {
    max-width: 600px; /* Limit the width of the container for better readability */
    margin: auto; /* Center the container */
    padding: 20px; /* Add some padding inside the container */
    background-color: #f5f5f5; /* Light grey background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  }
  
  .settings-container h2 {
    color: #333; /* Dark grey color for the text */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .user-info {
    display: flex; /* Align items in a row */
    align-items: center; /* Center items vertically */
    gap: 15px; /* Space between avatar and username */
    margin-bottom: 20px; /* Space below the user info section */
  }
  
  /* .user-info .user-avatar {
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    object-fit: cover;
  }
   */
  .settings-option {
    margin-bottom: 15px; /* Space between each setting option */
  }
  
  .settings-option label {
    font-weight: bold; /* Make label text bold */
    margin-right: 10px; /* Space between label and select box */
  }
  
  .settings-option select {
    padding: 5px; /* Padding inside the select box */
    border-radius: 4px; /* Slightly rounded corners for the select box */
    border: 1px solid #ccc; /* Light grey border */
  }
  
  /* .greeting{
    position: absolute;
    left: 0;
    align-items: center;  
  } */
  