/* tabs row */
ul.tabs_row{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    margin-bottom: 2em;
}
.show_hide_groups{
    display: block;
    padding: 8px 12px;
    font-size: 0.9em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333;
    width: 33%;
    appearance: none;
}
.tabs-control{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.selected-tab-display{
    position: absolute;
    left: 40%;
}

.only_defult_tab{
margin-bottom: 2em;
margin-top: 2em;
}

/* tabs */
.tabs_row li{
    margin-right: 15px;
    color: #bdbdbdbf ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;   

    color: #004d40;
    font-weight: 300;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 0.2em 0.8em;
}
.tabs_row li:last-child{
    margin-right:0;    
}
.tabs_row li .emoji {
    margin-right: 5px;
}

.chosen_tab{
    color: #004d40;
    font-weight: 600;
    border-bottom: #004d40 2px solid;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 0.2em 0.8em;
    background-color: #e0f2f1;
} 

.swiper-slide.tabs_row  {
    padding: 5px;
    padding-right: 15px;
    padding-left: 0px;
    width: fit-content;
}

.swiper {
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    margin-bottom: 1em;
    /* background-color: aliceblue; */
    border-radius: 10px;
    overflow: hidden; /* Ensures the blur effect and Swiper content do not overflow the container */
  }
  
  .swiper::after {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100px; /* Width of the blur effect */
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)); /* Fading effect */
    backdrop-filter: blur(3em); 
  }
  