.AddExpenses {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.AddExpenses button {
    font-size: 2rem;
    width: 10%;
}

.AddExpenses button.add_one_exp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 3em;
    border: 1px solid #00796b99;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #e0f2f1;
    color: #004d40;
    appearance: none;
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 150;
}

.plus {
    height: 35px;
    width: 35px;
}

/* popup */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 9998;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    align-items: center;
    z-index: 9999;
}

.modal .close-btn {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    margin: 0;
    font-size: 2.5em;
    width: min-content;
    color: #aaaaaa;
    float: right;
    font-weight: bold;
}

.modal input {
    all: unset;
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 16px;
    border-radius: 5px;
    padding: 5px 10px;
    width: 80%;
    background-color: #e0f2f1;
    height: 30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.modal input:focus {
    border-color: rgba(0, 121, 107, 0.6);
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 107, 0.25);
}

.modal button {
    display: block;
    margin-top: 10px;
}

.modal input.date_input {
    color: white;
    background-color: #00796b99;
    padding: 5px;
    border-radius: 10px;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal .amount_and_currency {
    display: flex;
    gap: 1em;
    width: inherit;
    align-items: center;
}

.modal input.expense_amount_input {
    height: 30px;
    font-size: 1.5em;
}

.add_expense_dropdown {
    display: block;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333;
    width: 50%;
    appearance: none;
    cursor: pointer;
}

.add_expense_dropdown::-ms-expand {
    display: none;
}

.add_expense_dropdown::after {
    content: '\25BC';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.add_expense_dropdown:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.modal .file_img_upload {
    display: none;
}

.modal .file-upload-label {
    display: flex;
    padding: 10px 20px;
    color: #004d40;
    background-color: #e0f2f1;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-upload-label:hover {
    background-color: #0056b3;
}

#file-name-display {
    margin-top: 10px;
}

.expense_owner {
    position: absolute;
    left: 0.8em;
    top: 0.8em;
    margin: 0;
    width: 80%;
    color: #aaaaaa;
    float: right;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
}

.modal_to_left {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0.5em;
    align-items: center;
}

.modal input.error {
    border: 1px solid rgb(226, 85, 85);
    box-shadow: 0 0 0 0.2rem rgba(226, 85, 85, 0.25);
}

.file-upload-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.upload-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

#file-name-display {
    margin-top: 10px;
}

.input-icon {
    margin-right: 1em;
    width: 30px;
    height: 30px;
    color: #aaaaaa;
}

.modal button.add_btn {
    width: fit-content;
}

.uneven-split-option {
    display: flex;
    align-items: center;
    gap: 1em;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .switch-slider {
    background-color: #2196F3;
}

input:checked + .switch-slider:before {
    transform: translateX(20px);
}

.uneven-split-option input[type="checkbox"] {
    display: none;
}

.uneven-split-details {
    width: 100%;
}

.user-share-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    gap: 0.5em;
    margin-bottom: 0.5em;
}

.user-share-input select {
    width: 50%;
    padding: 5px;
    border-radius: 5px;
    background-color: #e0f2f1;
    border: 1px solid #ccc;
}

.error-message {
    color: red;
    font-size: 0.9em;
    border-radius: 10px;
    padding: 5px 10px;
    box-shadow: 0 0 0 0.2rem rgba(226, 85, 85, 0.25);
}
