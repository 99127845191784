/* General container and box styling from previous example */
.signup-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
    position: relative; /* Use absolute if needed, based on your layout */
    flex-direction: column;
    gap: 1em;
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    margin-top: 3em;
}

.signup-box {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    text-align: center;
    width: 85%;
    max-width: 400px; /* Adjust based on your preference */
}

.signup-title {
    margin-bottom: 24px;
    color: #333;
    font-size: 24px;
}

.signup-input, .currency-dropdown {
    width: 85%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

/* Button styling */
.signup-btn {
    font-size: 16px;
    padding: 10px 24px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #4285f4;
    color: white;
    transition: background-color 0.3s;
}

.signup-btn:hover {
    background-color: #357ae8;
}

.file-input {
    margin: 10px 0;
}

.new_img_or_not{
margin-bottom: 1em;
}

.new_img_or_not input[type="radio"] {
    margin-top: 1em;
    margin-bottom: 1em;
}

.error-message {
    color: red;
    margin-top: 5px;
  }
  
