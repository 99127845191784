.Main{
padding-left: 0.8em;
padding-right: 0.8em;
overflow-x: hidden;
min-height: 800px;
}

.Main__header{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin: 1em;
margin-left: 0em;
}

@media screen and (min-width: 768px) {
    .Main {
        max-width: 430px;
        max-height: 930px;
        height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid #ccc;
        margin: 3em auto;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
    }

    /* Webkit (Chrome, Safari, Edge) */
    .Main::-webkit-scrollbar {
        width: 8px;
    }

    .Main::-webkit-scrollbar-track {
        background: #ccc;
        border-radius: 10px;
    }

    .Main::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 2px solid #ccc;
    }
}
